.ArticleInfo {}

.Splitter {
    background: #707070;
    border: 2.2px solid #707070;
    opacity: 1;
    border-radius: 50%;
    height: 1px;
    width: 1px;
    display: inline-block;
    margin: auto;
}

.TimeToRead {
    text-align: center;
    font: normal normal 600 clamp(12px, 0.26vw + 11px, 16px)/clamp(17px, 0.32vw + 15.8px, 22px) Noto Sans, sans-serif;
    letter-spacing: 0.32px;
    color: #707070;
    opacity: 1;
}

@media (max-width: 900px) {
    .TimeToRead {
        font-weight: bold;
        letter-spacing: 0.24px;
    }
}
