.AddProductToList {}

.Add2ListText {
    text-align: left;
    font: normal normal 600 20px/24px Montserrat, sans-serif;
    letter-spacing: 0px;
    color: #29A26E;
    opacity: 1;
    padding: 12px;
    background: #FFF;
}

.Add2ListText:hover {
    background: #008F46;
    border-radius: 12px;
    color: #FFF;
}

.AddFavouriteIcon {
    display: flex;
    align-items: center;
    width: 48px;
    height: 48px;
}

.FavouriteIcon {
    background-image: url('../../resources/View product/favourite.svg');
    background-size: cover;
    transition: transform 0.3s ease, opacity 0.3s ease, scale 0.3s ease;
    margin: auto;
    width: 24px;
    height: 24px;
    /*transform: translate(0, 0) scale(1) !important;*/
}

.FavouriteIcon:hover {
    opacity: 0.8;
    background-image: url('../../resources/View product/favourite selected.svg');
    transform: translate(-12px, -12px) scale(0.8) !important;
    padding: 8px;
}

.SelectedFavouriteIcon {
    background-image: url('../../resources/View product/favourite selected.svg');
    background-size: cover;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translate(-12px, -12px) !important;
    margin: auto;
    width: 32px;
    height: 32px;
}

.SelectedFavouriteIcon:hover {
    opacity: 0.6;
    background-image: url('../../resources/View product/favourite.svg');
    transform: translate(0, 0) scale(0.8) !important;
}

.Add2ListOptionText {
    text-align: left;
    font: normal normal 600 18px/22px Montserrat, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.AddedIcon,
.AddIcon,
.HoverIcon {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    background-image: url('../../resources/View product/add icon.svg');
    background-size: cover;
}

.AddedIcon {
    background-image: url('../../resources/View product/added icon.svg');
}

.HoverIcon {
    background-image: url('../../resources/View product/add icon hover.svg');
    display: none;
}

.ListItem:hover .AddedIcon,
.ListItem:hover .AddIcon {
    display: none; /* Hide the AddedIcon or AddIcon */
}

.ListItem:hover .HoverIcon {
    /* Show the HoverIcon when ListItem is hovered */
    display: block; /* Assuming HoverIcon is initially hidden */
}

.CreateListButton {
    width: 100%;
    padding: 12px 16px;
    border-radius: 11.25px;
    border: 1.4px solid #008F46;
    cursor: pointer;

    background: #fff;
    font: normal normal 600 18px/22px Montserrat, sans-serif;
    color: #29A26E;
}

.CreateListButton:hover {
    color: #fff;
    background: #008F46;
}

.ClosePanel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: 40px;
    cursor: pointer;
}

.CloseIcon {
    background-image: url('../../resources/View product/close icon.svg');
    background-size: cover;
    transition: transform 0.3s ease, opacity 0.3s ease, scale 0.3s ease;
    width: 32px;
    height: 32px;
}

.CloseIcon:hover {
    opacity: 0.9;
    background-image: url('../../resources/View product/close icon hover.svg');
    transform: translate(-8px, -8px) scale(0.6) !important;
    padding: 12px;
}

.SaveCancelButtonsRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 10px 20px;
}

.SaveCancelButton {
    background: #29A26E 0% 0% no-repeat padding-box;
    border-radius: 6px;
    border-style: none;
    opacity: 1;

    text-align: center;
    font: normal normal 500 clamp(18px, 0.1vw + 18px, 20px)/clamp(21px, 0.19vw + 20.3px, 24px) Montserrat, sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 10px 18px;
}
