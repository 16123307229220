html, body {
  background-color: #F6FBF8;
  position: relative;
  z-index: -10;
  scroll-behavior: smooth;
  max-width: 1920px;
  margin: 0 auto;
}

.App {
  text-align: center;
  width: 100%;
  overflow-x: hidden;
}

.App-header {
  width: 100%;
  height: clamp(78px, 6.67vw + 53px, 181px);
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #0000000D;
  opacity: 1;
}

.Page {
  margin: 0 120px;
}

/* MUI Breakpoints:
   xs - 0px,
   sm - 600px,
   md - 900px,
   lg - 1200px,
   xl - 1536px.
*/
@media (max-width: 900px) {
  .Page {
    margin: 0 20px;
  }
}
