.ExpertChipSmall,
.ExpertChip {
    display: flex;
    text-align: left;
    color: #707070;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    box-shadow: 5px 10px 20px #00000008;
    border-radius: 113px;
    max-width: 460px;
    max-height: 173px;
}

.ExpertChipSmall {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
}

.ChipTextSmall,
.ChipText {
    margin: auto;
    margin-left: clamp(9px, 0.45vw + 7.3px, 16px);
}

.ChipTextSmall {
    transform: scale(0.75);
    transform-origin: center;
}

.ChipName {
    font: normal normal 600 24px/33px Noto Sans, sans-serif;
    letter-spacing: 0.48px;
    font-size: clamp(14px, 1.55vw - 5.8px, 24px);
    white-space: nowrap;
}

.ChipTitle {
    font: normal normal 500 20px/27px Noto Sans, sans-serif;
    letter-spacing: 0.4px;
    font-size: clamp(12px, 0.52vw + 10.1px, 20px);
    white-space: nowrap;
}
