.Article {
    text-align: left;
}

.Title {
    text-align: left;
    font: normal normal bold clamp(18px, 1.7vw + 12px, 45px)/clamp(24px, 2.39vw + 15px, 61px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.Content p {
    text-align: left;
    font: normal normal normal clamp(16px, 0.26vw + 15px, 20px)/clamp(22px, 0.52vw + 20.1px, 30px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 400;
}

.Content h3 {
    text-align: left;
    font: normal normal bold clamp(16px, 0.52vw + 14.1px, 24px)/clamp(22px, 0.45vw + 20.3px, 29px) Spartan, serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.Content img {
    width: 80%;
    margin: auto;
    position: relative;
    left: 10%;
}

@media (max-width: 900px) {
    .NavigateBackText {
        display: none;
    }

    .Content p {
        opacity: 0.7;
    }

    .Content img {
        width: 100%;
        left: 0%;
    }
}
