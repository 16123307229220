.ProductBriefPanel {
    border-radius: 15px;
    border: 1px solid #E1E1E1;
    background: #FFF;
    /* Cards drop shadow */
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
}

.DetailImgContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 40px;
    border: 1px solid #E1E1E1;
    background: #FFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.DetailProductImg {
    margin: 80px 80px 30px 80px;
    width: clamp(160px, 20vw + 84px, 460px);
    height: clamp(160px, 20vw + 84px, 460px);
    object-fit: contain;
}

.DetailStarIcon {
    position: relative;
    left: 110px;
    top: -85px;
    width: clamp(45px, 1.62vw + 39px, 70px);
    transform: scale(3);
}

.Container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
}

.ProductInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 10px 0 10px;
}

.ProductOtherText,
.ProductNameText {
    text-align: left;
    font: normal normal 500 clamp(18px, 0.1vw + 18px, 20px)/clamp(21px, 0.19vw + 20.3px, 24px) Noto Sans, sans-serif;
    letter-spacing: 0;
    color: #333333;
    opacity: 1;
}

.ProductNameTextDetail {
    text-align: left;
    font: normal normal bold clamp(20px, 0.65vw + 17.6px, 30px)/clamp(26px, 0.71vw + 23.3px, 37px) Montserrat, sans-serif;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
}

.ProductOtherText {
    opacity: 0.68;
    font-size: 14px;
    margin-top: 4px;
}

.ProductOtherTextDetail {
    margin-top: 18px;
    text-align: left;
    font: normal normal normal clamp(14px, 0.26vw + 13px, 18px)/clamp(14px, 0.52vw + 12.1px, 22px) Montserrat, sans-serif;
    letter-spacing: 0;
    font-weight: 500;
    color: #707070;
    opacity: 1;
}

.ProductTags {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
}

.ProductTag {
    width: clamp(27px, 1.85vw + 20px, 55px);
    padding-right: clamp(20px, 6.47vw - 4.3px, 120px);
}

.ImgContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
}

.ProductImg {
    width: 160px;
    height: 160px;
    margin-right: 2px;
    object-fit: contain;
}

.StarIcon {
    position: relative;
    left: 0;
    top: -25px;
    width: clamp(45px, 1.62vw + 39px, 70px);
    transform: scale(1.2);
}

.Favourite {
    padding-right: 6px;
}

.ButtonIcon {
    cursor: pointer;
}

.SelectedFavouriteIcon,
.FavouriteIcon,
.NoHoverFavouriteIcon {
    width: 30px;
    height: 30px;
    background-image: url('../../resources/Search Results/favourite icon.svg');
    background-size: cover;
}

.SelectedFavouriteIcon {
    background-image: url('../../resources/Search Results/favourite icon selected.svg');
}

.FavouriteIcon:hover {
    background-image: url('../../resources/Search Results/favourite icon selected.svg');
    opacity: 0.7;
}

.RemoveButton {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-end;

    filter: invert(44%) sepia(0%) saturate(63%) hue-rotate(168deg) brightness(109%) contrast(93%);

    text-align: left;
    font: normal normal 500 14px/21px Noto Sans, sans-serif;
    letter-spacing: 0;
    color: #333333;
    opacity: 1;

    padding: 6px 20px;
}

.Divider {
    border-left: 1px solid #333333;
    height: auto;
    min-height: 160px;
    opacity: 0.38;
}

@media (max-width: 900px) {
    .Divider {
        display: none;
    }

    .ProductBriefPanel {
        background: #FFFFFF 0 0 no-repeat padding-box;
        border-radius: 15px;
        border: 0.5px solid #00000033;
        opacity: 1;
        width: clamp(320px, 85vw, 500px);
        margin: auto;
    }

    .StarIcon {
        left: 10px;
        top: -25px;
        transform: scale(1.8);
    }
}
