.MyMenu {
    display: flex;
}

.MenuIcon {
    width: clamp(20px, 2.2vw + 11.8px, 54px);
}

.MenuItemBold,
.MenuItem {
    text-align: left;
    font: normal normal normal 24px/30px Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #090909;
    opacity: 1;
    font-weight: 500;
    padding: 5px 60px 5px 30px;
}

.MenuItemBold {
    font-weight: 700;
}

.MenuItemListHead {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: clamp(78px, 6.67vw + 53px, 181px);
    padding: 0 20px;
}

.MenuLogo {
    width: 120px;
}
