.Learn {}

.Card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #0000000D;
    border-radius: 15px;
    opacity: 1;
    width: calc(clamp(60px, 26.86vw - 40.7px, 475px) - 22px * 2);
    padding: 28px 22px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: stretch;
}

.CardImg {
    margin: auto;
    width: 100%;
    object-fit: contain;
    border-radius: 15px;
}

.CardTitle {
    text-decoration: none;
    padding: 15px 2px 0px 2px;
    text-align: left;
}

.CardContent {
    text-decoration: none;
    padding: 10px 0px;
    text-align: left;
}

.Center {
    margin: auto;
    position: relative;
    top: 50%;
}

.DisabledArrowIcon,
.ArrowIcon {
    margin: auto 0;
    position: relative;
    left: 0;
    right: clamp(-48px, 1.04vw - 51.9px, -32px);
}

.DisabledArrowIcon {
    filter: invert(44%) sepia(0%) saturate(63%) hue-rotate(168deg) brightness(109%) contrast(93%);
    opacity: 0.55;
}

.CardLink {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 900px) {
    .CardTitle {
        padding-top: 8px;
        height: clamp(30px, 1.94vw + 22.7px, 60px);
    }

    .CardContent {
        display: none;
    }

    .TimeToRead {
        font-weight: 700;
    }
}
