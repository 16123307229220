.AlertDialog {}

.DialogContent {
    text-align: center;
    font: normal normal normal clamp(17px, 0.32vw + 16px, 22px)/clamp(23px, 0.26vw + 22px, 27px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.65;
    margin: 0 60px;
}
