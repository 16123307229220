.ImageWithParagraphs {}

::-webkit-scrollbar {
    display: none;
}

.ParagraphView {
    padding-top: clamp(15px, 5.83vw - 6.9px, 105px);
}

.Image {
    width: 70%;
    max-width: 540px;
}

.ParagraphTitle {
    text-align: left;
    font: normal normal bold clamp(35px, 2.91vw + 24.1px, 80px)/clamp(48px, 4.01vw + 33px, 110px) le-monde-livre-classic-byol, serif;
    letter-spacing: 1.2px;
    opacity: 1;
}

.ParagraphText {
    text-align: left;
    font: normal normal normal clamp(18px, 0.7vw + 15px, 28px)/clamp(24px, 0.91vw + 20.6px, 38px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
    font-weight: 500;
}

.ParagraphSummary {
    text-align: left;
    font: normal normal bold 20px/27px Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #6A9D87;
    opacity: 1;
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ParagraphTextReadMore {
    color: #29A26E;
    font: normal normal normal 16px/22px Noto Sans, sans-serif;
    font-weight: 500;
    text-decoration: underline;
}

.NavLinksContainer {
    position: sticky;
    bottom: 25vh;
    margin-left: 120px;
    width: 50%;
}

.NavLinksContainerBg {
    text-align: left;
    background: #F0F9F5 0% 0% no-repeat padding-box;
    opacity: 1;
    filter: blur(50px);
    -webkit-filter: blur(50px);
    height: 200px;
    width: 70%;
    z-index: -1;
}

.NavLinks {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 2;
    width: 80%;
    text-align: left;
}

.NavLink {
    padding: 5px 0;
}

.SelectedNavLink {
    padding: 12px 0;
}

.SelectedNavLink a,
.NavLink a {
    text-decoration: none;
    font: normal normal bold clamp(20px, 0.32vw + 18.8px, 25px)/clamp(27px, 0.45vw + 25.3px, 34px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #186544;
    opacity: 0.33;
    transition: transform 0.6s;
}

.SelectedNavLink a {
    display: list-item;
    font: normal normal bold 35px/47px Noto Sans, sans-serif;
    color: #6A9D87;
    opacity: 1;
    transition: transform 0.6s;
}

.ParagraphSlider {
    position: sticky;
    bottom: 40vh;
    margin-right: 30px;
    float: right;
}

@media (max-width: 900px) {
    .Image {
        width: 50%;
    }
}
