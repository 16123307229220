.Home {}

.HomePage {
    margin: 0 76px;
}

section {
    display: flex;
    flex-direction: column;
}

.FindFoodSection {
    height: 100vh;
}

.HomeHeaderSpaceTaken,
.HomeHeaderGreen,
.HomeHeader {
    height: clamp(78px, 6.67vw + 53px, 181px);
}

.HomeHeader,
.HomeHeaderGreen {
    width: min(100vw, 1920px);;
    position: fixed;
    top: 0;
    z-index: 10;
}

.HomeHeaderGreen {
    background-color: #29A26E;
    animation: StickyHeaderAnimation 3s;
    -moz-animation: StickyHeaderAnimation 3s;
    -webkit-animation: StickyHeaderAnimation 3s;
    -o-animation: StickyHeaderAnimation 3s;
}

@keyframes StickyHeaderAnimation {
    0% {
        background-color: transparent;
        color: transparent;
    }
    35% {
        background-color: #29A26E;
    }
    100% {
        background-color: #29A26E;
    }
}

.HomeFindFoodBg {
    background: url("../../resources/Home/desktop bg.png") no-repeat 25% 50%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /*pointer-events: all;*/
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.HomeTextTitle {
    text-align: center;
    font: normal normal normal clamp(40px, 3.88vw + 25.5px, 100px)/clamp(55px, 5.37vw + 34.9px, 138px) le-monde-livre-classic-byol, serif;
    letter-spacing: 1.2px;
    color: #FFFFFF;
    opacity: 1;
    font-weight: 600;
}

.HomeTextTitleAccentImg {
    position: relative;
    width: clamp(60px, 5.83vw + 38px, 150px);
    bottom: calc(-1 * clamp(27px, 1.85vw + 20px, 55px));
}

.HomeSearchBar {
    width: 100%;
    max-width: 1200px;
}

.HomeDownwardArrow {
    position: absolute;
    bottom: 50px;
    z-index: 0;
    width: 40px;
    transform: translateX(-50%);
}

.SectionView {
    overflow: hidden;
    padding: clamp(30px, 5.83vw + 8.1px, 120px) 0;
}

.HomeMobileText {
    text-align: center !important;
}

.HomeSectionTitleText {
    text-align: left;
    font: normal normal normal clamp(25px, 2.27vw + 16.5px, 60px)/clamp(35px, 3.11vw + 23.3px, 83px) le-monde-livre-classic-byol, serif;
    font-weight: 700;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
}

.HomeSaveToMobileSectionBgColor{
    background-color: #E7EFEB;
}

.HomeSaveToMobileSection {
    padding: 0 clamp(42px, 14.11vw - 10.9px, 260px);
}

.HomeSaveToMobileSectionBox {
    background: #FFFFFF 0 0 no-repeat padding-box;
    border-radius: 25px;
    opacity: 0.85;
}

.HomeSaveToMobileSectionText {
    padding: 10px clamp(0px, 16.83vw - 63.1px, 260px) 0 clamp(0px, 16.83vw - 63.1px, 260px);
    font: normal normal normal clamp(16px, 0.65vw + 13.6px, 26px)/clamp(22px, 0.9vw + 18px, 36px) Noto Sans, sans-serif;
    font-weight: 500;
}

.HomeSaveToMobileTextTitle {
    text-align: left;
    text-decoration: underline;
    font: normal normal normal clamp(16px, 0.91vw + 12.6px, 30px)/clamp(22px, 1.23vw + 17.4px, 41px) Noto Sans, sans-serif;
    letter-spacing: 0;
    color: #29A26E;
    opacity: 1;
    font-weight: 500;
}

.HomeSaveToMobileText {
    text-align: left;
    font: normal normal normal clamp(16px, 0.84vw + 12.9px, 29px)/clamp(22px, 1.1vw + 17.9px, 39px) Noto Sans, sans-serif;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
    margin-top: 8px;
}

.HomeSaveToMobileShareIcon {
    padding: 0 6px;
    width: clamp(13px, 0.78vw + 10.1px, 25px);
}

.HomeSaveToMobileSettingIcon {
    padding: 0 16px;
    width: clamp(4px, 0.19vw + 3.3px, 7px);
}

.HomeWhatIsStarSearch {
    background: #FFFFFF 0 0 no-repeat padding-box;
    opacity: 1;
}

.HomeWhatIsStarSearchBg {
    background-image: url("../../resources/Home/What is Star Search/grey shape bg.png");
    background-repeat: no-repeat;
    background-position: 230% 120%;
    background-size: 90% 120%;
}

.HomeTextSectionTitle {
    text-align: left;
    font: normal normal bold clamp(35px, 2.91vw + 24.1px, 80px)/clamp(48px, 4.01vw + 33px, 110px) le-monde-livre-classic-byol, serif;
    letter-spacing: 1.2px;
    opacity: 1;
}

.HomeWhatIsStarSearchText p {
    text-align: left;
    font: normal normal normal clamp(18px, 0.7vw + 15px, 28px)/clamp(24px, 0.91vw + 20.6px, 38px) Noto Sans, sans-serif;
    letter-spacing: 0;
    color: #000000;
    opacity: 0.7;
    font-weight: 500;
}

.HomeWhatIsStarRating {
    background: #29A26E12 0 0 no-repeat padding-box;
}

/* middle wide screen */
@media (max-width: 1200px) {
    .HomeHeader {
        background: #E7EFEB;
    }

    .HomeFindFoodBgContainer,
    .HomeFindFoodBg {
        top: clamp(78px, 6.67vw + 53px, 181px);
        height: calc(100vh - 2 * clamp(78px, 6.67vw + 53px, 181px) - 10px);
    }

    .HomeSaveToMobileSectionAndroid {
        margin-top: 30px
    }

    /* middle wide and long screen */
    @media (min-height: 800px) {
        .HomeFindFoodBgContainer,
        .HomeFindFoodBg {
            height: calc(100vh - clamp(78px, 6.67vw + 53px, 181px));
            position: static;
        }
    }

    /* middle wide and short screen */
    @media (max-height: 800px) {
        .HomeFindFoodBgContainer,
        .HomeFindFoodBg {
            height: calc(100vh - clamp(78px, 6.67vw + 53px, 181px));
            min-height: 500px;
            position: static;
        }
    }
}

/* narrow screen */
@media (max-width: 900px) {
    .HomePage {
        margin: 0 16px;
    }

    .HomeFindFoodBgContainer,
    .HomeFindFoodBg {
        top: clamp(78px, 6.67vw + 53px, 181px);
        margin: 0 20px;
        height: calc(100vh - 2 * clamp(78px, 6.67vw + 53px, 181px) - 10px);
        border-color: #0C2E20;
        border-radius: 25px;
    }

    .HomeFindFoodContent {
        margin: 0 20px;
    }

    .HomeMobileText {
        text-align: left !important;
    }

    .HomeSaveToMobileSectionBgColor {
        background-color: #F6FBF8;
    }

    .HomeSectionTitleText {
        font-weight: 600;
    }

    .HomeSaveToMobileSectionBox {
        border-radius: 12px;
    }

    .HomeSaveToMobileSectionText {
        padding: 0;
    }

    .HomeWhatIsStarSearchBg {
        background: url("../../resources/Home/What is Star Search/grey shape bg.png") no-repeat 15% 50%;
        background-size: 250vw 70vh;
    }

    .HomeDownwardArrow {
        bottom: 30px;
    }

    .HomeWhatIsStarRating {
        background-color: #F6FBF8;
    }
}


/* short screen */
@media (max-height: 799px) {
    .HomeDownwardArrow {
        display: none;
    }

    .FindFoodSection {
        height: auto;
    }

    /* wide and short screen */
    @media (min-width: 1201px) {
        .HomeFindFoodBg {
            height: 100vh;
            min-height: clamp(500px, 13vw + 450px, 700px);
            position: static;
        }
    }

    /* narrow and short screen */
    @media (max-width: 1023px) {
        .HomeFindFoodBg {
            height: 100%;
            min-height: 600px;
            max-height: calc(100vh - 2 * clamp(78px, 6.67vw + 53px, 181px));
            position: static;
        }
    }
}
