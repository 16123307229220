@import url("https://use.typekit.net/jnp8sqe.css");

.FAQ {
    max-width: 1500px;
    margin: auto;
}

.CardSelected,
.Card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #0000000A;
    border-radius: 15px;
    opacity: 1;
    width: 45%;
    padding: 1%;
    margin: 1%;
    max-width: 387px;
}

.CardSelected {
    border: 1px solid #29A26E;
}

.CardSelectedIcon,
.CardIcon {
    height: clamp(32px, 1.17vw + 27.6px, 50px);
    filter: invert(0%) sepia(96%) saturate(14%) hue-rotate(273deg) brightness(104%) contrast(104%);
}

.CardSelectedIcon {
    filter: invert(45%) sepia(11%) saturate(2822%) hue-rotate(102deg) brightness(113%) contrast(74%);
}

.CardTitle {
    text-decoration: none;
    padding: 15px 2px 0px 2px;
    text-align: center;
}

.CardContent {
    text-decoration: none;
    padding: 10px 20px 2px 20px;
    text-align: center;
}

.Summary {
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Detail {
    padding-bottom: 15px;
    animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@media (max-width: 900px) {
    .CardTitle {
        padding-top: 8px;
    }

    .CardContent {
        display: none;
    }
}

