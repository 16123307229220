.Footer {}

.Logo {
    text-align: left;
    display: block;
}

.MobileFooter {
    width: 100%;
    position: fixed;
    left:0;
    bottom:0;
    right:0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #0000000D;
    opacity: 1;
    height: clamp(78px, 6.67vw + 53px, 181px);
    display: none;
}

.MobileFooterSpaceTaken {
    display: none;
    height: clamp(78px, 6.67vw + 53px, 181px);
}

.MobileFooterNav {
    margin: auto 60px;
    width: 100%;
}

.MobileFooterNav img {
    padding: 10px;
    /* #a6a6a6*/
    filter: invert(59%) sepia(67%) saturate(0%) hue-rotate(194deg) brightness(97%) contrast(94%);
}

.MobileFooterNav span {
    text-align: left;
    font: normal normal normal clamp(10px, 0.52vw + 8.1px, 18px)/clamp(14px, 0.26vw + 13px, 18px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.65;
    font-weight: 500;
}

.DesktopFooter {
    background: #414141 0% 0% no-repeat padding-box;
    opacity: 1;
}

.DesktopPage {
    margin: 0 76px;
}

.SectionView {
    overflow: hidden;
    padding: clamp(15px, 1.62vw + 8.9px, 40px) 0;
}

.DesktopFooterTextTitle {
    text-align: left;
    font: normal normal 600 clamp(20px, 1.29vw + 15.2px, 40px)/clamp(27px, 1.85vw + 20px, 55px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #FFF;
    opacity: 1;
}

.DesktopFooterSectionTextTitle {
    color: #FFF !important;
    font-weight: 600 !important;
}

.UnsetA {
    text-decoration: none !important;
    color: inherit;
}

.DesktopFooterTextTiny {
    text-align: left;
    font: normal normal normal clamp(16px, 0.13vw + 15.5px, 18px)/clamp(22px, 0.13vw + 21.5px, 24px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #FFF;
    opacity: 1;
    padding: 5px 0;
    overflow-wrap: anywhere;
}

.DesktopFooterText {
    text-align: left;
    font: normal normal 500 clamp(14px, 0.39vw + 12.5px, 20px)/clamp(19px, 0.52vw + 17.1px, 27px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #FFF;
    opacity: 1;
    padding: clamp(14px, 0.4vw + 12px, 20px) 0;
}

.DesktopFooterText a {
    text-decoration: none;
    color: #FFF;
}

.DesktopFooterLogo {
    padding: 8px 20px 8px 0;
    width: 80%;
    min-width: 160px;
    max-width: 280px;
}

.MobileFooterIconSelected {
    /* #29a26e */
    filter: invert(45%) sepia(80%) saturate(347%) hue-rotate(102deg) brightness(99%) contrast(96%) !important;
}

.MobileFooterLabelSelected {
    opacity: 1 !important;
}

@media (max-width: 900px) {
    .MobileFooterNav {
        margin: auto;
    }

    .DesktopPage {
        margin: 0 16px;
    }

    .DesktopFooterText {
        font-weight: 600;
    }

    .DesktopFooterTextTiny {
        padding: 2px 0;
    }

    .DesktopFooterLogo {
        width: 40%;
    }

    .MobileFooterSpaceTaken,
    .MobileFooter {
        display: flex;
    }
}
