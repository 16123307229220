.TermsOfUse {}

.TableHeading {
    padding-top: 30px;
}

.ListItem {
    list-style-type: none;
    text-align: left;
    text-decoration: underline;
    font: normal normal 600 clamp(16px, 0.26vw + 15px, 20px)/40px Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.TermsOfUseContentText {
    text-align: left;
    font: normal normal normal clamp(16px, 0.26vw + 15px, 20px)/30px Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 400;
}

@media (max-width: 900px) {
    .TermsOfUseContentText {
        color: #707070;
        font-weight: 500;
        line-height: 33px;
    }

}
