.Carousel {
    position: relative;
    top: 10px;
}

.Indicators {
    display: none;
}

.DisabledArrowIcon,
.ArrowIcon {
    width: 36px;
    margin: 6px;
    filter: invert(50%) sepia(90%) saturate(80%) hue-rotate(209deg) brightness(62%) contrast(100%);
}

.DisabledArrowIcon {
    filter: invert(44%) sepia(0%) saturate(63%) hue-rotate(168deg) brightness(109%) contrast(93%);
    opacity: 0.55;
}

.CarouselItem {
    text-align: left;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 50px #0000000D;
    opacity: 1;
    margin: clamp(13px, 2.72vw + 2.8px, 55px);
    width: 78vw;
}

.CarouselItemRound {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #0000000D;
    border-radius: 15px;
    opacity: 1;
    margin: 0 5px 30px 30px;
    padding-bottom: 20px;
    width: 78vw;
}

.CarouselItemRoundContainer {
    padding: clamp(8px, 1.29vw + 3.2px, 28px) clamp(9px, 0.84vw + 5.9px, 22px);
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-around;
}

.CarouselItemImage {
    height: clamp(48px, 1.62vw + 41.9px, 73px);
    margin-bottom: clamp(0px, 0.65vw - 2.4px, 10px);
}

.CarouselItemBigImage {
    width: 100%;
    max-height: 70vh;
    border-radius: 8px;
}

.CarouselItemTitle {
    text-align: left;
    font: normal normal bold clamp(18px, 0.52vw + 16.1px, 26px)/clamp(24px, 0.78vw + 21.1px, 36px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.75;
    text-stroke: 1px black;
    -webkit-text-stroke: 1px black;
}

.CarouselItemLongContentTitle {
    text-align: left;
    font: normal normal 600 clamp(15px, 0.58vw + 12.8px, 24px)/clamp(20px, 0.84vw + 16.9px, 33px) Noto Sans, sans-serif;
    letter-spacing: clamp(0.3px, 0.01vw + 0.3px, 0.48px);
    color: #000000;
    opacity: 1;
}

.CarouselItemContent {
    text-align: left;
    font: normal normal normal clamp(18px, 0.41vw + 16.5px, 24px)/clamp(24px, 0.58vw + 21.8px, 33px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
}

.ParagraphTextReadMore {
    color: #29A26E;
    font: normal normal normal 16px/22px Noto Sans, sans-serif;
    font-weight: 500;
    text-decoration: underline;
}

.CarouselItemLongContent {
    text-align: left;
    font: normal normal normal clamp(14px, 0.26vw + 13px, 18px)/clamp(19px, 0.32vw + 17.8px, 24px) Noto Sans, sans-serif;
    letter-spacing: clamp(0.28px, 0.01vw + 0.2px, 0.36px);
    color: #000000;
    opacity: 1;
}

.TimeToRead {
    text-align: left;
    font: normal normal normal clamp(12px, 0.26vw + 11px, 16px)/clamp(17px, 0.32vw + 15.8px, 22px) Noto Sans, sans-serif;
    letter-spacing: 0.32px;
    color: #707070;
    opacity: 1;
    padding-bottom: 3px;
    font-weight: 600;
}

.CarouselContainer {
    padding-top: 50px;
    overflow: hidden;
    margin: auto;
}

@media (min-width: 1200px) {
    .CarouselContainer {
        width: 90vw !important;
    }
}

@media (max-width: 1200px) {
    .Indicators {
        display: flex;
        justify-content: right;
        position: absolute;
        top: -50px;
        right: 76px;
    }

    .CarouselContainer {
        padding-top: 0;
    }

    .CarouselItem {
        box-shadow: 0px 10px 30px #00000017;
    }

    .CarouselItemRound {
        border-radius: 8px;
    }

    .TimeToRead {
        font-weight: bold;
        letter-spacing: 0.24px;
        color: #000000;
    }
}

@media (max-width: 900px) {
    .CarouselItemTitle {
        font-weight: 500;
    }

    .Indicators {
        right: 20px;
    }
}

