.MyLists {}

.MyListContainer {
    padding: 0 40px;
}

.Head {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    height: clamp(78px, 6.67vw + 53px, 181px);
    padding: 0 20px;
}

.FlexRow {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 20px;
}

.HeadText {
    text-align: left;
    font: normal normal bold clamp(16px, 0.26vw + 15px, 20px)/clamp(22px, 0.32vw + 20.8px, 27px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #29A26E;
    opacity: 1;
    padding-left: 10px;
}

.RightArrowIcon {
    width: clamp(10px, 0.26vw + 9px, 14px);
    margin-right: 10px;
    cursor: pointer;
}

.ListNameText {
    text-align: left;
    font: normal normal 500 clamp(18px, 0.1vw + 18px, 20px)/clamp(21px, 0.19vw + 20.3px, 24px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    padding: clamp(16px, 0.91vw + 12.6px, 30px);
    cursor: pointer;
}

.GreyRightArrowIcon {
    width: clamp(7px, 0.32vw + 5.8px, 12px);
    opacity: 0.52;
    margin-right: 10px;
    cursor: pointer;
}

.BackArrowRow {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    padding: 20px;
}

.BackArrowSectionRow {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
}

.BackArrowRowIcon {
    cursor: pointer;
    padding-right: clamp(12px, 0.52vw + 10.1px, 20px);
}

.ListNameTitleText {
    text-align: left;
    font: normal normal 600 clamp(16px, 0.52vw + 14.1px, 24px)/24px Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

@media (max-width: 900px) {
    .MyListContainer {
        padding: 0;
    }
}
