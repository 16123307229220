.Container1920 {
  max-width: 1920px;
  margin: auto;
}

.TextTitle {
  text-align: left;
  font: normal normal normal clamp(30px, 1.94vw + 22.7px, 60px)/clamp(41px, 2.72vw + 30.8px, 83px) le-monde-livre-classic-byol, serif;
  letter-spacing: 1.2px;
  color: #000000;
  opacity: 1;
  padding-top: clamp(18px, 0.52vw + 16px, 26px);
}

.TextBigHeading {
  text-align: left;
  font: normal normal bold clamp(18px, 0.61vw + 16px, 40px)/clamp(21px, 2.2vw + 12.8px, 55px) le-monde-livre-classic-byol, serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.TextHeading {
  text-align: left;
  font: normal normal bold clamp(18px, 1.1vw + 13.7px, 35px)/clamp(35px, 0.84vw + 31.9px, 48px) le-monde-livre-classic-byol, serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 700;
}

.TextHeading1 {
  text-align: left;
  font: normal normal bold clamp(20px, 0.65vw + 17.6px, 30px)/clamp(28px, 0.84vw + 24.9px, 41px) le-monde-livre-classic-byol, serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 700;
}

.TextHeading2 {
  text-align: left;
  font: normal normal bold clamp(18px, 0.52vw + 16.1px, 26px)/clamp(25px, 0.71vw + 22.3px, 36px) le-monde-livre-classic-byol, serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 700;
}

.TextHeading3 {
  text-align: left;
  font: normal normal bold clamp(16px, 0.52vw + 14.1px, 24px)/29px Spartan, serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.TextParagraph1 {
  text-align: left;
  font: normal normal normal clamp(16px, 0.65vw + 13.6px, 26px)/33px Noto Sans, sans-serif;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-weight: 500;
}

.TextParagraph2 {
  text-align: left;
  font: normal normal normal clamp(18px, 0.31vw + 16.9px, 22px)/clamp(24px, 0.39vw + 22.5px, 30px) Noto Sans, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
}

.TextParagraph3 {
  text-align: left;
  font: normal normal normal clamp(16px, 0.39vw + 14.5px, 22px)/33px Noto Sans, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
}

.TextParagraph4 {
  text-align: left;
  font: normal normal normal clamp(16px, 0.26vw + 15px, 20px)/clamp(22px, 0.32vw + 20.8px, 27px) Noto Sans, sans-serif;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-weight: 500;
}

.GreenButton {
  background: #29A26E 0% 0% no-repeat padding-box;
  border-radius: 32px;
  border-style: none;

  padding: 16px 45px;
  text-align: left;
  font: normal normal 600 clamp(16px, 0.52vw + 14.1px, 24px)/clamp(24px, 0.58vw + 21.8px, 33px) Noto Sans, sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.FullWindowWidth {
  margin: 0 -120px;
}

.NavigateBackText {
  text-align: left;
  font: normal normal 600 18px/24px Segoe UI, sans-serif;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  cursor: pointer;
}

.ShowFor900SmallScreenOnly {
  display: none;
}

.ShowFor900BigScreenOnly {
  display: block;
}

.ShowFor1200SmallScreenOnly {
  display: none;
}

.ShowFor1200BigScreenOnly {
  display: block;
}

@media (max-width: 900px) {
  .TextParagraph3,
  .TextParagraph4 {
    line-height: 1.8;
    color: #707070;
  }

  .FullWindowWidth {
    margin: 0 -20px;
  }

  .ShowFor900SmallScreenOnly {
    display: block;
  }

  .ShowFor900BigScreenOnly {
    display: none;
  }
}

@media (max-width: 1200px) {
  .ShowFor1200SmallScreenOnly {
    display: block;
  }

  .ShowFor1200BigScreenOnly {
    display: none;
  }
}
