.HomeHeader,
.Header {
    margin: 0 120px;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.HomeHeader {
    height: clamp(78px, 6.67vw + 53px, 181px);
}

.HeaderLogo {
    margin: auto 0;
}

.HeaderSearchBar {
    margin: auto;
    width: clamp(260px, 44vw + 84px, 920px);
}

.HeaderMyLists {
    margin: auto;
}

.HeaderMenu {
    display: flex;
    float: right;
}

.Logo {
    display: block;
    width: clamp(140px, 8vw + 100px, 260px);
}

.LogoSmall {
    display: none;
    width: clamp(45px, 0.97vw + 41.4px, 60px);
}

.HomeLogoSmall {
    display: none;
    width: clamp(140px, 8vw + 100px, 260px);
}

.MyLists{
    text-align: left;
    font: normal normal bold clamp(16px, 0.26vw + 15px, 20px)/27px Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 700;
}

.HomeHeaderMenuLink {
    margin: auto;
}

.HomeHeaderMenuLink a {
    text-decoration: none;
    padding: 5px 5px 5px clamp(15px, 1.94vw + 7.7px, 45px);
    color: #FFFFFF;
}

@media (max-width: 900px) {
    .HomeHeader,
    .Header {
        margin: 0 20px;
    }
}

@media (max-width: 1200px) {
    .HeaderMyLists,
    .Logo {
        display: none;
    }

    .HomeLogoSmall,
    .LogoSmall {
        display: block;
    }
}
