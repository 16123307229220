.Product {
    background-color: #FFFFFF;
}

.RatingReason {
    display: none;
}

@media (max-width: 900px) {
    .Product {
        background-color: unset;
    }

    .NavigateBackText {
        display: none;
    }

    .RatingReason {
        display: block;
    }
}
