@font-face{
  font-family: 'Spartan';
  src: url('./resources/font/Spartan-SemiBold.ttf')  format('truetype');
  font-weight: 600;
}

@font-face{
  font-family: 'Spartan';
  src: url('./resources/font/Spartan-Bold.ttf')  format('truetype');
  font-weight: 700;
}

body {
  max-width: 1920px;
  margin: 0 auto;
  font-family: noto-sans, sans-serif, le-monde-livre-classic-byol, serif, montserrat, sans-serif, 
  Spartan, serif, 'Segoe UI', sans-serif, -apple-system, BlinkMacSystemFont,
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
