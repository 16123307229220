.SearchBar {}

.HeaderSearchBar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-evenly;
    justify-content: space-evenly;
    align-items: center;
}

.HeaderSearchBarFadeText {
    text-align: center;
    margin-bottom: 10px;
    font: normal normal 600 15px/20px Noto Sans, sans-serif;
}

.HeaderSearchBarSection {
    flex: 2;
    width: 100%;
}

.HeaderSearchBarPopper {
    position: absolute;
    width: clamp(260px, 44vw + 84px, 920px);
}

.SearchBarSection {
    padding: 14px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    flex: 3.5;
}

.SearchBarSectionLabel {
    text-align: left;
    font: normal normal bold clamp(12px, 0.26vw + 11px, 16px)/clamp(17px, 0.32vw + 15.8px, 22px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.75;
    padding-left: 7px;
}

/* input text */
.SearchBarSectionSelectInput,
.SearchBarSectionBrandInput,
.SearchBarSectionItemInput,
.HeaderSearchBarSectionCategorySelect,
.HeaderSearchBarSectionBrandInput,
.HeaderSearchBarSectionItemInput {
    text-align: left;
    font: normal normal normal clamp(15px, 0.71vw + 12.3px, 26px)/clamp(20px, 1.04vw + 16.1px, 36px) Noto Sans, sans-serif !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.9 !important;
    border: none;
    outline: none;
    min-width: 112px;
    width: 100%;
    background-color: inherit;
    padding-left: 7px;
    text-overflow: ellipsis;
}

/* header searchbar input text */
.HeaderSearchBarSectionCategorySelect,
.HeaderSearchBarSectionBrandInput,
.HeaderSearchBarSectionItemInput {
    font: normal normal normal clamp(14px, 0.39vw + 12.5px, 20px)/clamp(19px, 0.52vw + 17.1px, 27px) Noto Sans, sans-serif !important;
}

/* Start of "custom input text for each one" */
/* food item */
.HeaderSearchBarSectionItemInput,
.SearchBarSectionItemInput {
    padding-left: 0;
}

/* brand */
.HeaderSearchBarSectionBrandInput,
.SearchBarSectionBrandInput{
    padding-top: 7px;
}

/* category */
.HeaderSearchBarSectionCategorySelect {
    padding-top: 10px;
}

.SearchBarSectionSelectInput {
    padding-top: 7px;
}

/* End of "custom input text for each one" */

.Splitter {
    background: #707070;
    border: 1.5px solid #707070;
    opacity: 1;
    border-radius: 50%;
    height: 1px;
    width: 1px;
    display: block;
    margin: auto;
}

.SearchBarSectionButton {
    width: 100%;
    height: 100%;
    min-height: 58px;
    max-height: 94px;
    max-width: 224px;
    float: right;

    background: #29A26E 0% 0% no-repeat padding-box;
    border-radius: 47px;
    border-style: none;
    opacity: 1;

    text-align: center;
    font: normal normal 500 clamp(19px, 0.71vw + 16.3px, 30px)/clamp(26px, 0.97vw + 22.4px, 41px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.SearchBarDisabled {
    background-color: #ccc !important;
    color: #999 !important;
    cursor: not-allowed !important;
}

.SearchBarSearchSection {
    flex: 1.5;
}

.SearchBarSectionIcon {
    width: clamp(50px, 0.91vw + 46.6px, 64px);
    height: clamp(50px, 0.91vw + 46.6px, 64px);

    float: right;
    margin-right: 12px;

    position: relative;
    top: 50%;
    transform: translateY(-50%);

    background: #29A26E 0% 0% no-repeat padding-box;
    border-radius: 50%;
    border-style: none;
    opacity: 1;

    text-align: center;
    color: #FFFFFF;
}

.SearchBarSectionIconSmall {
    width: clamp(25px, 0.45vw + 23.3px, 32px);
    height: clamp(25px, 0.45vw + 23.3px, 32px);

    background: #29A26E 0% 0% no-repeat padding-box;
    border-radius: 50%;
    border-style: none;
    opacity: 1;

    text-align: center;
    color: #FFFFFF;
}

.SearchBarSectionDivider {
    height: 60px;
    border: 0.5px;
    border-color: rgba(0, 0, 0, 0.25);
}

.SearchBarFindFoodSection {
    padding-left: clamp(0px, 1.1vw + 11px, 30px);
    margin-right: 2px;
    width: 95%;
}

.SearchBarCategorySection {
    width: 100%;
    margin-top: 6px;
}

.HomeSelectDefaultOption,
.HeaderSelectDefaultOption,
.HomeInputPlaceholder input::placeholder,
.HeaderInputPlaceholder input::placeholder {
    text-align: left;
    font: normal normal normal clamp(14px, 0.39vw + 12.5px, 20px)/clamp(19px, 0.52vw + 17.1px, 27px) Noto Sans, sans-serif !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.65 !important;
}

.HomeSelectDefaultOption,
.HomeInputPlaceholder input::placeholder {
    font: normal normal normal clamp(15px, 0.71vw + 12.3px, 26px)/clamp(20px, 1.04vw + 16.1px, 36px) Noto Sans, sans-serif !important;
}

@media (max-width: 900px) {
    .HeaderSearchBarSectionCategorySelect {
        padding-top: 3px;
        padding-left: 0;
    }

    .HeaderSearchBarSectionBrandInput {
        padding-top: 0px;
    }

    .HeaderSearchBarSectionItemInput {
        opacity: 0.5;
    }

    .SearchBarFindFoodSection {
        padding-left: 0px;
        padding-top: 20px;
        margin-right: 2px;
    }

    .SearchBarSection {
        padding: 0px 30px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-content: space-between;
    }

    .SearchBarSectionButton {
        border-radius: 29px;
        max-height: unset;
        max-width: unset;
        float: unset;
    }

    .SearchBarCategorySection {
        margin-top: 0;
    }
}
