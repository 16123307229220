.PopularSearches {
    background: #F2F2F2;
    padding: 50px 0;
}

.SearchText {
    border-radius: 10px;
    border: 1px solid rgba(65, 65, 65, 0.50);
    padding: 8px;
    word-wrap: break-word;
    cursor: pointer;
    text-align: center;
    font: normal normal 500 clamp(16px, 0.52vw + 14.1px, 24px)/clamp(24px, 0.58vw + 21.8px, 33px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.75;
}

.HomePopularSearches div {
    padding: 10px;
    text-align: left;
    font: normal normal bold clamp(16px, 0.26vw + 15px, 20px)/27px Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-weight: 700;
}

.HomePopularSearches button {
    padding: 10px;
    width: 100%;
    background: #FFFFFF59 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    opacity: 1;
    text-align: center;
    font: normal normal 600 24px/33px Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
}

@media (max-width: 900px) {
    .HomePopularSearches {
        display: none;
    }
}
