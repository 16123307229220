.SearchResults {}

.TitleSortContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.SortContainer {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
}

.RefineSearchText {
    padding: 8px 0;
    color: rgba(65, 65, 65, 0.60);
    font: normal normal 600 clamp(14px, 0.26vw + 13px, 18px)/normal Noto Sans, sans-serif;
}

.SortText {
    text-align: left;
    font: normal normal normal clamp(12px, 0.52vw + 10px, 20px)/clamp(17px, 0.65vw + 14.6px, 27px) Noto Sans, sans-serif !important;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
}

.PageNavText {
    color: rgba(65, 65, 65, 0.60);
    font: normal normal 400 18px/normal Noto Sans, sans-serif;
    margin-top: 40px;
}

.PageNavText span {
    padding: 0 15px;
}

.FloatingBtn {
    position: fixed;
    bottom: calc(clamp(78px, 6.67vw + 53px, 181px) + 30px);
    right: 30px;
    cursor: pointer;
    transform: scale(2);
}


/* wide screen */
@media (min-width: 900px) {
    .FloatingBtn {
        display: none;
    }

    .RefineSearchText {
        color: #414141;
    }
}

/* narrow screen */
@media (max-width: 1000px) {
    .TitleSortContainer {
        flex-direction: column;
        align-items: flex-start;
    }

    .SortContainer {
        margin-top: 20px;
        width: 100%;
        justify-content: flex-end;
    }

    @media (max-width: 899px) {
        .TitleSortContainer {
            width: clamp(320px,85vw,500px);
            margin: auto;
        }
    }
}
