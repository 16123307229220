.BetterForYou {}

.Tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
}

.Tabs div {
    margin-right: 30px;
    padding: 20px 0;
    text-align: left;
    font: normal normal bold 26px/36px Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
}

.UnselectedTab {
    opacity: 0.4;
}

.SelectedTab {
    opacity: 1;
    animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
}

.TabContentShow {
    width: 100%;
    position: absolute;
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear;
}

.TabContentHide {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}

.LearnTitleText {
    text-align: left;
    font: normal normal 500 18px/21px Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.LearnText {
    padding: 20px 0;
    text-align: left;
    font: normal normal 500 clamp(16px, 0.52vw + 14.1px, 24px)/clamp(22px, 0.71vw + 19.3px, 33px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
}

.SeeMoreText {
    color: #29A26E;
    font: normal normal 600 20px/27px Noto Sans, sans-serif;
    text-decoration: none;
    float: right;
}

.ReadMoreText {
    color: #29A26E;
    font: normal normal 500 16px/22px Noto Sans, sans-serif;
    text-decoration: underline;
}

.DrawerPage {
    width: 100%;
    height: 100vh;
    margin: 0 -20px;
    z-index: 0;
    position: fixed;
    top: calc(100vh - clamp(78px, 6.67vw + 53px, 181px));
}

.DrawerTitleText {
    padding: 35px 0 15px 0;
    text-align: center;
}

.DrawerList {
    overflow: scroll;
    height: 100vh;
    padding-top: 30px;
}
