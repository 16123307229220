.ContactUs {}

.Box {
    display: grid;
    margin: 10px 0 50px 0;
    padding: clamp(20px, 0.97vw + 16.4px, 35px) clamp(50px, 1.94vw + 42.7px, 80px);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 40px #00000012;
    border-radius: 10px;
    opacity: 1;
}

.Label {
    padding: 8px 0;
    text-align: left;
    font: normal normal bold clamp(14px, 0.39vw + 12.5px, 20px)/clamp(19px, 0.52vw + 17.1px, 27px) Noto Sans, sans-serif;
    letter-spacing: 0px;
    color: #323232;
    opacity: 1;
}

.SendButtonText {
    padding: 15px 0;
    text-align: left;
    font: normal normal bold clamp(14px, 0.39vw + 12.5px, 20px)/clamp(17px, 0.45vw + 15.3px, 24px) Century Gothic, sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-family: century-gothic, sans-serif;
}

